<template>
  <div class="auth-wrapper auth-v2">

    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        :to="{ path: '/' }"
        class="brand-logo"
      >
        <logo />

        <h2 class="brand-text text-primary ml-1">
          {{ appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="6"
        xl="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="6"
        xl="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold d-none d-lg-block"
          >
            <div>
              <h3>{{ appName }}</h3>
              {{ $t('about_the_project') }}
            </div>

          </b-card-title>

          <b-card-text class="mb-1">

            <small>{{ $t('auth.Please create your account') }}</small>
          </b-card-text>
          <!--          <div>-->
          <!--            <b-card-title-->
          <!--              class="mb-1 font-weight-bold d-none d-lg-block"-->
          <!--              title-tag="h2"-->
          <!--            >-->
          <!--              {{ $t('auth.Welcome to') }} OneTimeJob! 👋-->
          <!--            </b-card-title>-->
          <!--            <b-card-text class="mb-2">-->
          <!--              <feather-icon-->
          <!--                icon="PhoneCallIcon"-->
          <!--                class="mr-25"-->
          <!--              />{{ $t('Find the best contractors for the geo-referenced one-time jobs quickly and easily') }}-->

          <!--            </b-card-text>-->
          <!--            <b-card-text class="mb-2">-->
          <!--              <feather-icon-->
          <!--                icon="ToolIcon"-->
          <!--                class="mr-25"-->
          <!--              />{{ $t('Provide services without wasting time and money on management') }}-->

          <!--            </b-card-text>-->
          <!--          </div>-->

          <register-form />

          <b-card-text class="text-center mt-2">
            <span>{{ $t('auth.Already have an account') }}?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;{{ $t('auth.Sign in instead') }}</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
    <!--    <sidebar-->
    <!--      v-model="isEventHandlerSidebarActive"-->
    <!--    />-->
  </div>
</template>

<script>

import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BImg, BCardText, BCardTitle,
} from 'bootstrap-vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import RegisterForm from './components/RegisterForm.vue'
import { localize } from 'vee-validate'
import i18n from '@/libs/i18n'

// import sidebar from './SideBar.vue'

export default {
  components: {
    Logo,
    BRow,
    BImg,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    // sidebar,
    RegisterForm,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Register',
    // all titles will be injected into this template
    titleTemplate: '%s | OneTimeJob',
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      { charset: 'utf-8' },
      {
        property: 'og:title',
        content: 'Create your account',
        template: chunk => `${chunk} | OneTimeJob`,
        vmid: 'og:title',
      },
    ],
  },

  data() {
    return {
      sideImg: require('@/assets/images/pages/login.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },

  },

  setup() {
    // const isEventHandlerSidebarActive = ref(false)
    localize(i18n.locale)
    const { appName } = $themeConfig.app
    return {
      appName,
      // isEventHandlerSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style scoped>
.img-fluid {
  filter: opacity(0.7);
}
</style>
